<template>
	<modal ref="modal" :titulo="`${action} servicio de paquetería`" tamano="modal-md" :adicional="`${action}`" @adicional="handleActions" no-aceptar>
		<div class="row">
			<div class="col-12">
				<p class="text-center my-3">¿Desea {{ action }} este servicio de paquetería?</p>
			</div>
		</div>
    </modal>
</template>

<script>
import Servicio from "~/services/serviciosPaqueteria";
export default {
	props: {
        servicio: {
            type: Object,
            default: () => { return {} }
        }
    },
    data(){
        return {
			idServicio: parseInt(this.$route.params.id),
			action: ''
        }
    },
    methods: {
        toggle(state){
			if(state == 1) this.action = 'Desactivar'
			if(state == 0) this.action = 'Activar'
            this.$refs.modal.toggle()
        },
		async handleActions(){
			const payload = {
				nombre: this.servicio.nombre,
				descripcion: this.servicio.descripcion,
				token: this.servicio.token_api,
				estado : this.action == 'Desactivar' ? 0 : 1
			}
			const { data } = await Servicio.editServicio(this.idServicio, payload)
			this.notificacion('', data.mensaje, "success")
			this.$emit('update', payload.estado)
			this.$refs.modal.toggle()
		}
    }
}
</script>

<style lang="css" scoped>
</style>
